.infoWrapper {
  display: inline-block;
  border-radius: 12px;
  overflow: hidden;

  .hide { opacity: 0; } //hnmmmm

  h2, p {
    color: #fff;
    width: 100%;
    text-align: center;
    transition: opacity 0.2s, transform 0.3s;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 0;
  }

  p {
    bottom: 0;
    font-size: 14px;
  }

  &:hover { //is this needed
    transition: all .3s ease-in-out;
  }
  
  &:hover:before { //is this needed
    transition: all .3s ease-in-out;
  }

  // img {
  //   &:hover {
  //     transition: all .3s ease-in-out;
  //   }
    
  //   &:after {
  //     content: '';
  //     background-color: rgba(0,0,0,.6);
  //     width: 100%;
  //     height: 100%;
  //     top: 0;
  //     left: 0;
  //     opacity: 0;
  //   }
    
  //   &:hover {
  //     transition: all .3s ease-in-out;
  //   }
  // }

}

.vintage {
  padding: 0;
  display: inline-block;

  h2 { 
    top: 50%;
    transform: translate3d(0, 60px, 0);
    text-align: center; 
  }
  
  p { 
    opacity: 0;
    bottom: 0;
    transform: translate3d(0, -10px, 0);
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
  }
  
  &:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
    z-index: 2;
    bottom: -100%;
    left: 0;
  }
  
  &:hover:before {
    bottom: 0;
  }
  
  &:hover {
    h2 {
      bottom: 40px;
      transform: translate3d(0, 20px, 0);
    }
    
    p {
      opacity: 1;
      transform: translate3d(0, -30px, 0);
    }
  }
}
