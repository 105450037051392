@charset "utf-8";

// Import only what you need from Bulma
@import "../node_modules/bulma/bulma.sass";
// @import "~bootstrap/scss/bootstrap";

.App {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue';
  background {} }

.Background {
  color: white;
  background: linear-gradient(90deg, rgba(105,106,142,1) 37%, rgba(152,112,144,1) 100%); //rgba(116,118,150,1) 37%, rgba(156,125,154,1) 100%)
  background-size: cover;
  min-height: 100vh;
  position: static;
  background-attachment: fixed; }

.column {
  margin-left: 30px; }

.photo {
  border-radius: 12px;
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
  // box-shadow:
  // 0 1.4px 1.7px rgba(0, 0, 0, 0.017),
  // 0 3.3px 4px rgba(0, 0, 0, 0.024),
  // 0 6.3px 7.5px rgba(0, 0, 0, 0.03),
  // 0 11.2px 13.4px rgba(0, 0, 0, 0.036),
  // 0 20.9px 25.1px rgba(0, 0, 0, 0.043),
  // 0 50px 60px rgba(0, 0, 0, 0.06)
  // box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)
  // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)
  // box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
  overflow: hidden;
  box-sizing: border-box;

  // &:hover
 }  //   box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025)



.section-a .container,
.section-c .container,
.section-d .container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  align-items: center;
  justify-content: center; }
