.Title-text {
  /*Text Aesthetics*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue';
  font-weight: bold;
  font-size: 15vmin; /*calc(10px + 15vmin); */
  
  /*Text Positioning*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /*Background Clipping/Masking https://www.hongkiat.com/blog/css-masking/ */
  background-image: url("../images/textImg.jpeg");
  -webkit-background-clip: text;
	background-clip: text;
  color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;

  /*Background Positioning*/
  background-size: 90vmin;
  background-position: 55% 20%; 
  translate: translate3d( 0, 0, 0);
}

